exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-event-enquiry-js": () => import("./../../../src/templates/eventEnquiry.js" /* webpackChunkName: "component---src-templates-event-enquiry-js" */),
  "component---src-templates-general-enquiry-js": () => import("./../../../src/templates/generalEnquiry.js" /* webpackChunkName: "component---src-templates-general-enquiry-js" */),
  "component---src-templates-microsites-js": () => import("./../../../src/templates/microsites.js" /* webpackChunkName: "component---src-templates-microsites-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-promotion-js": () => import("./../../../src/templates/promotion.js" /* webpackChunkName: "component---src-templates-promotion-js" */),
  "component---src-templates-spa-enquiry-js": () => import("./../../../src/templates/spaEnquiry.js" /* webpackChunkName: "component---src-templates-spa-enquiry-js" */),
  "component---src-templates-stay-enquiry-js": () => import("./../../../src/templates/stayEnquiry.js" /* webpackChunkName: "component---src-templates-stay-enquiry-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */)
}

